import styled from 'styled-components';

export const ConnectionsContainer = styled.div`
  margin-bottom: 1rem;
`;

export const AddButton = styled.button`
  background: #35a76e;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem 0.8rem 1rem;
  font-weight: 600;
  color: #fff;
  & svg {
    margin-right: 1rem;
  }
`;

export const ConnectionContainer = styled.div`
  background: #fff;
  border: 3px solid #e2e2e2;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const Panel = styled.div`
  padding: 0.5rem;
  font-weight: 600;
  background-color: #e6e6e6;
`;

export const Content = styled.div`
  padding: 1rem;
`;

export const FloatRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
