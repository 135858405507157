import { useMemo, useState } from 'react';
import * as C from '../../../common';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { queryStores } from '../../../../../../constants';
import { fetchWearableProviders } from '../../../../../../api/flow';

export default function useConnectionSchema({
  slug,
  initialValues,
  currentConnections = [],
  connId,
}) {
  // eslint-disable-next-line no-unused-vars
  const [selectedService, setSelectedService] = useState(
    initialValues?.connection?.achievement_slug || null,
  );
  const { data: wearables } = useQuery(
    [queryStores.flowWearableProviders],
    () => fetchWearableProviders(),
    {
      refetchOnWindowFocus: false,
    },
  );

  /**
   * Filter the app names uniquely
   * and by already selected connections
   */
  const serviceNames = useMemo(() => {
    const names = _.uniq(
      wearables?.map((wearable) => ({
        label: wearable.name,
        value: wearable.slug,
      })),
    );
    return names.filter((wearable) => {
      const itemNotFound = currentConnections.indexOf(wearable.name) < 0;
      // if the index of the item is equal to the connection id
      // we shouldnt remove it from the list since its the current item in use
      if (currentConnections.indexOf(wearable.name) === connId) {
        return true;
      }
      return itemNotFound;
    });
  }, [wearables, currentConnections, connId]);

  const schema = useMemo(() => {
    return [
      {
        name: 'connection',
        input: [
          C.title,
          {
            name: 'achievement_slug',
            label: 'Service Name',
            interface: 'selectadvanced',
            required: true,
            options: serviceNames,
            labelTooltip: {
              title: 'Service Name',
              content: '(vendor integration) to be setup.',
            },
          },
        ],
      },
    ];
  }, [serviceNames]);

  return { schema, setSelectedService };
}
