export const getStudyVersion = (version: string) => {
  if (!version) return;
  const semanticVersion = version.split('.');
  const [major, minor] = semanticVersion;
  return `${major}.${minor}`;
};

export const getIncrementedMinorVersion = (version: string) => {
  if (!version) return;

  const semanticVersion = version.split('.');
  const [major, minor] = semanticVersion;
  const minorAsNumber = Number(minor);

  if (minor !== undefined) {
    return `${major}.${minorAsNumber + 1}`;
  }

  return `${major}.0`;
};

export const getMajorVersion = (version: string) => {
  if (!version) return;
  const semanticVersion = version.split('.');
  const [major] = semanticVersion;
  return `${major}`;
};
