import * as TileIcons from './assets/tile_icons';
import * as ComponentIcons from './assets/ComponentIcons';
import _ from 'lodash';
import ascendingOptionListOfInt from './utils/ascendingOptionsListOfInt';

export const APP_ADMIN = 'admin';

export default {
  user: {
    log_in: 'USER/LOG_IN',
    logged_in: 'USER/LOGGED_IN',
    log_out: 'USER/LOG_OUT',
    get_studies: 'USER/GET_STUDIES',
    set_studies: 'USER/SET_STUDIES',
    assign_authorization: 'USER/ASSIGN_AUTHORIZATION',
  },
  study: {
    set_info: 'STUDY/SET_INFO',
    set_meta: 'STUDY/SET_META',
    clear: 'STUDY/CLEAR',
  },
  graph: {
    set: 'GRAPH/SET',
    update: 'GRAPH/UPDATE',
    clear: 'GRAPH/CLEAR',
    modifyAtPath: 'GRAPH/MODIFY_AT_PATH',
    persist: 'GRAPH/PERSIST',
    set_creating: 'GRAPH/SET_CREATING',
  },
  publish: {
    set_publishing: 'PUBLISH/SET_PUBLISHING',
  },
  publish_errors: {
    add: 'PUBLISH_ERRORS/ADD',
    remove: 'PUBLISH_ERRORS/REMOVE',
    clear: 'PUBLISH_ERRORS/CLEAR',
  },
  async_operations: {
    add: 'ASYNC_OPERATIONS/ADD',
    add_job: 'ASYNC_OPERATIONS/ADD_JOB',
    remove: 'ASYNC_OPERATIONS/REMOVE',
    remove_job: 'ASYNC_OPERATIONS/REMOVE_JOB',
    clear: 'ASYNC_OPERATIONS/CLEAR',
    clear_job: 'ASYNC_OPERATIONS/CLEAR_JOB',
  },
  sequences: {
    set: 'SEQUENCES/SET',
    update: 'SEQUENCES/UPDATE',
    clear: 'SEQUENCES/CLEAR',
    delete: 'SEQUENCES/DELETE',
    deleteIn: 'SEQUENCES/DELETE_IN_SEQUENCE',
  },
  archipelagos: {
    set: 'ARCHIPELAGOS/SET',
    update: 'ARCHIPELAGOS/UPDATE',
    clear: 'ARCHIPELAGOS/CLEAR',
    delete: 'ARCHIPELAGOS/DELETE',
    deleteIn: 'ARCHIPELAGOS/DELETE_IN_SEQUENCE',
  },
  reminders: {
    set: 'REMINDERS/SET',
    clear: 'REMINDERS/CLEAR',
    delete: 'REMINDERS/DELETE',
    update: 'REMINDERS/UPDATE',
    mapReminders: 'SLATE/MAP_REMINDERS_AND_DELAYS',
    mapRemindersClear: 'SLATE/CLEAR_MAP_REMINDERS_AND_DELAYS',
  },
  tiles: {
    bulkEdit: 'TILES/BULK_EDIT',
    set: 'TILES/SET',
    clear: 'TILES/CLEAR',
    delete: 'TILES/DELETE',
    update: 'TILES/UPDATE',
  },
  slate: {
    slateConfiguration: 'SLATE/CONFIGURATION',
    armsCollect: 'SLATE/ARMS_COLLECT',
    typesCollect: 'SLATE/TYPES_COLLECT',
    armFilter: 'SLATE/ARMS_FILTER',
    typeFilter: 'SLATE/TYPE_FILTER',
    modify_resource: 'SLATE/MODIFY_RESOURCE',
    toggleDisplayReminder: 'SLATE/TOGGLE_DISPLAY_REMINDER',
    getResourceOrder: 'SLATE/GENERATE_RESOURCE_ORDER',
    clearResourceOrder: 'SLATE/CLEAR_RESOURCE_ORDER',
    select: 'SLATE/SELECT',
    selectClearType: 'SLATE/SELECT_CLEAR_TYPE',
    selectClearAll: 'SLATE/SELECT_CLEAR_ALL',
    setSlateCoordinates: 'SLATE/SET_SLATE_COORDINATES',
    resetSlateCoordinates: 'SLATE/RESET_SLATE_COORDINATES',
    setSlateSidebarWidth: 'SLATE/SET_SIDEBAR_WIDTH',
  },
  slate_configure: {
    set: 'SLATE_CONFIGURE/SET',
    clear: 'SLATE_CONFIGURE/CLEAR',
  },
  participant: {
    set_detail: 'PARTICIPANT/SET_DETAIL',
    current_enrollment_node_set: 'PARTICIPANT/CURRENT_ENROLLMENT_NODE_SET',
    current_event_set: 'PARTICIPANT/CURRENT_EVENT_SET',
    clear: 'PARTICIPANT/CLEAR',
  },
  participants: {
    set: 'PARTICIPANTS/SET',
    update: 'PARTICIPANTS/UPDATE',
    clear: 'PARTICIPANTS/CLEAR',
  },
  // Events that come from the slate tiles and reminders
  event_selectors: {
    set: 'EVENT_SELECTORS/SET',
    clear: 'EVENT_SELECTORS/CLEAR',
  },
  // Saved sets of participants
  pe_cohorts: {
    set: 'PE_COHORTS/SET',
    clear: 'PE_COHORTS/CLEAR',
    start_loading: 'PE_COHORTS/START_LOADING',
    stop_loading: 'PE_COHORTS/STOP_LOADING',
  },
  // Events where participant data is saved on the enrollment record
  pe_user_data_events: {
    set: 'PE_USER_DATA_EVENTS/SET',
    clear: 'PE_USER_DATA_EVENTS/CLEAR',
  },
  event_delays: {
    retrieve: 'EVENT_DELAYS/RETRIEVE',
    review: 'EVENT_DELAYS/REVIEWED',
    clear: 'EVENT_DELAYS/CLEAR',
    detail: 'EVENT_DELAYS/DETAIL',
  },
  configure: {
    prepare: 'CONFIGURE/PREPARE',
    setPreviewState: 'CONFIGURE/SET_PREVIEW_STATE',
    setExperiences: 'CONFIGURE/SET_EXPERIENCES',
    selectExperience: 'CONFIGURE/SELECT_EXPERIENCE',
  },
  locale: {
    setLocale: 'LOCALE/SET_LOCALE',
    clearLocale: 'LOCALE/CLEAR_LOCALE',
    getLanguages: 'LOCALE/GET_LANGUAGES',
    setLanguages: 'LOCALE/SET_LANGUAGES',
  },
};

export const omit_from_tile_data = ['uuid', 'sequence_uuid'];
export const omit_from_sequence_data = [
  'uuid',
  'tiles',
  'sequence_uuid',
  'reminders',
];

export const InterfaceIcons = {
  address: ComponentIcons.address,
  aside: ComponentIcons.paragraph,
  hidden: ComponentIcons.hidden,
  checkboxWithNone: ComponentIcons.checkboxWithNone,
  insuranceProviderId: ComponentIcons.insuranceProviderId,
  contentAcceptance: ComponentIcons.contentAcceptance,
  error_summary: ComponentIcons.paragraph,
  markdown: ComponentIcons.markdown,
  mask: ComponentIcons.mask,
  mask_custom: ComponentIcons.mask,
  text: ComponentIcons.text,
  height: ComponentIcons.height,
  height_intl: ComponentIcons.height,
  weight_intl: ComponentIcons.height,
  textarea: ComponentIcons.textarea,
  number: ComponentIcons.number,
  select: ComponentIcons.select,
  selectadvanced: ComponentIcons.selectadvanced,
  checkbox: ComponentIcons.checkbox,
  checkbox2: ComponentIcons.checkbox,
  typeahead: ComponentIcons.typeahead,
  radio: ComponentIcons.radio,
  radio2: ComponentIcons.radio,
  gender: ComponentIcons.radio,
  date: ComponentIcons.date,
  datetime: ComponentIcons.date,
  date_of_birth: ComponentIcons.date,
  'time (24-hour clock)': ComponentIcons.time,
  email: ComponentIcons.email,
  email_confirm: ComponentIcons.email_confirm,
  tel: ComponentIcons.tel,
  phone_intl: ComponentIcons.tel,
  password: ComponentIcons.password,
  passwordmodern: ComponentIcons.password,
  passwordonly: ComponentIcons.passwordonly,
  heading: ComponentIcons.heading,
  paragraph: ComponentIcons.paragraph,
  zip_code: ComponentIcons.zip_code,
  state: ComponentIcons.state,
  scrollbox: ComponentIcons.scrollbox,
  scrollboxLarge: ComponentIcons.scrollboxLarge,
  file_uploader: ComponentIcons.file_uploader,
  year: ComponentIcons.year,
  'select-number-range': ComponentIcons.select_number_range,
  todays_date: ComponentIcons.todays_date,
  signature: ComponentIcons.signature,
  matrix_0to10: ComponentIcons.matrix_0to10,
  matrix_0to5: ComponentIcons.matrix_0to5,
  matrix_0to10_na: ComponentIcons.matrix_0to10_na,
  matrix_0to5_na: ComponentIcons.matrix_0to5_na,
  matrix_days: ComponentIcons.matrix_days,
  matrix_days_na: ComponentIcons.matrix_days_na,
  phq9: ComponentIcons.matrix_0to10,
  gad7: ComponentIcons.matrix_0to10,
  simple_matrix: ComponentIcons.matrix_0to10,
  ess: ComponentIcons.matrix_0to10,
  acq5: ComponentIcons.matrix_0to10,
  sex: ComponentIcons.radio,
  us_resident: ComponentIcons.radio,
  country_resident: ComponentIcons.radio,
  age: ComponentIcons.radio,
  ethnicity: ComponentIcons.radio,
  pregnancy: ComponentIcons.radio,
  english: ComponentIcons.radio,
  language: ComponentIcons.radio,
  typing_test: ComponentIcons.typeahead,
  validatedText: ComponentIcons.text,
  range_slider: ComponentIcons.slider,
  time: ComponentIcons.time,
  ranking: ComponentIcons.selectadvanced
};

export const Nodes = {
  api_request: {
    title: 'API Request',
    description: 'Api Request',
    icon: TileIcons.take_survey,
    tile_description: ['description'],
  },
  assign_external_id: {
    title: 'Assign External ID',
    description: 'Assign External ID',
    icon: TileIcons.connect_external,
    tile_description: ['description'],
  },
  castor_edc: {
    title: 'Castor EDC',
    description: 'Castor EDC',
    icon: TileIcons.castor_edc,
    tile_description: [
      'external_title',
      'description',
      'completion_message',
      'action_title',
    ],
  },
  castor_epro_survey: {
    title: 'Castor ePRO Survey',
    description: 'Castor ePRO Survey',
    icon: TileIcons.castor_epro_survey,
    tile_description: [
      'external_title',
      'description',
      'completion_message',
      'action_title',
    ],
  },
  communications: {
    title: 'Communications',
    description: 'Send the study participant an email and/or push notification',
    icon: TileIcons.message,
    tile_description: ['description'],
  },
  connect_app: {
    title: 'Connect App',
    description: 'Connect An App',
    icon: TileIcons.connect_app,
    tile_description: ['description', 'action_title'],
  },
  connect_camcog: {
    title: 'Cambridge Cognition',
    description: 'Link participant to online cognition tests',
    icon: TileIcons.camcog,
    tile_description: ['descriptions'],
  },
  connect_ehr: {
    title: 'Connect EHR',
    description: 'Connect to Electronic Health Records providers',
    icon: TileIcons.ehr,
    tile_description: ['description'],
  },
  connect_external: {
    title: 'Connect External',
    description: 'Connect an external service',
    icon: TileIcons.connect_external,
    tile_description: ['external_title', 'description'],
  },
  connect_wearable: {
    title: 'Connect Wearable',
    description: 'Connect a wearable device',
    icon: TileIcons.connect_app,
    tile_description: ['description'],
  },
  delay: {
    title: 'Delay',
    description: 'Set a delay between reminders being sent out',
    icon: TileIcons.delay,
    tile_description: ['description'],
  },
  disconnect_app: {
    title: 'Disconnect App',
    description: 'Disconnect An App',
    icon: TileIcons.disconnect_app,
    tile_description: ['description', 'action_title'],
  },
  education: {
    title: 'Education',
    description: 'Education Screen',
    icon: TileIcons.education,
    tile_description: ['hero_title', 'hero_content', 'how_you_help_heading'],
  },
  email_message: {
    title: 'Email Message',
    description: 'Send the study participant an email',
    icon: TileIcons.message,
    tile_description: ['subject', 'body[0].section'],
  },
  emit_event: {
    title: 'Emit Event',
    description: 'Emit an event',
    icon: TileIcons.event_delay,
    tile_description: [],
  },
  evaluate_cap: {
    title: 'Evaluate Cap',
    description: 'Cap the number of participants',
    icon: TileIcons.evaluate_cap,
    tile_description: ['description'],
  },
  event_delay: {
    title: 'Event Delay',
    description: 'A delaying event',
    icon: TileIcons.event_delay,
    tile_description: ['description', 'completion_message'],
  },
  experian_validator: {
    title: 'Identity Verification',
    description: 'Identity Verification',
    icon: TileIcons.experian_validator,
    tile_description: ['prompt_message', 'success_message', 'fail_message'],
  },
  follow_up_survey: {
    title: 'Follow Up Survey',
    description: 'Follow Up Survey',
    icon: TileIcons.follow_up_survey,
    tile_description: [
      'external_title',
      'description',
      'completion_message',
      'action_title',
    ],
  },
  informed_consent: {
    title: 'Informed Consent',
    description: 'Informed Consent',
    icon: TileIcons.informed_consent,
    tile_description: ['form[0].label', 'description'],
  },
  milestone: {
    title: 'Milestone',
    description: 'A Milestone has been reached',
    icon: TileIcons.milestone,
    tile_description: ['description', 'completion_message'],
  },
  multi_factor_authenticate: {
    title: '2FA',
    description: 'Multifactor Authentication',
    icon: TileIcons.multi_factor_authenticate,
    tile_description: ['description'],
  },
  qualtrics_survey: {
    title: 'Qualtrics Survey',
    description: 'Qualtrics Survey',
    icon: TileIcons.qualtrics_survey,
    tile_description: [
      'external_title',
      'description',
      'completion_message',
      'action_title',
    ],
  },
  registration: {
    title: 'Registration',
    description: 'Registration Form',
    icon: TileIcons.registration,
    tile_description: ['form[0].label'],
  },
  mtl_kit_order: {
    title: 'MTL Kit Order',
    description: 'MTL Kit Order',
    icon: TileIcons.shipping,
    tile_description: ['description'],
  },
  screener: {
    title: 'Screener',
    description: 'Screener Question',
    icon: TileIcons.screener,
    tile_description: ['form[0].label'],
  },
  shipping: {
    title: 'Shipping',
    description: 'Shipping',
    icon: TileIcons.shipping,
    tile_description: ['description'],
  },
  sms_message: {
    title: 'SMS Message',
    description: 'Send the study participant a text message',
    icon: TileIcons.message,
    tile_description: ['description'],
  },
  survey: {
    title: 'Survey',
    description: 'Survey',
    icon: TileIcons.survey,
    tile_description: [
      'external_title',
      'description',
      'completion_message',
      'action_title',
    ],
  },
  take_survey: {
    title: 'Take Survey',
    description: 'Take A Survey',
    icon: TileIcons.take_survey,
    tile_description: [
      'external_title',
      'description',
      'completion_message',
      'action_title',
    ],
  },
  redirect_user: {
    title: 'Redirect User',
    description: 'Redirects the user',
    icon: TileIcons.take_survey,
    tile_description: ['description'],
  },
  terminal_state: {
    title: 'Terminal State',
    description: 'Member has reached a terminal state',
    icon: TileIcons.terminal_state,
    tile_description: ['description'],
  },
  verify_email: {
    title: 'Verify Email',
    description: 'Send the study participant a verification email',
    icon: TileIcons.verify_email,
    tile_description: ['description'],
  },
  visit_portal: {
    title: 'Visit Portal',
    description:
      'Tile is used by CRCs for entering participant info into the Platform',
    icon: TileIcons.verify_email,
    tile_description: [],
  },
};

export const MessagingNodes = _.pick(Nodes, [
  'email_message',
  'communications',
]);

export const TileNodes = _.pick(Nodes, [
  'screener',
  'terminal_state',
  'connect_app',
  'connect_wearable',
  'delay',
  'event_delay',
  'take_survey',
  'connect_external',
  'informed_consent',
  'milestone',
  'education',
  'evaluate_cap',
  'disconnect_app',
  'assign_external_id',
  'connect_ehr',
  'connect_camcog',
  'shipping',
  'mtl_kit_order',
]);

export const ReminderNodes = _.pick(Nodes, [
  'email_message',
  'delay',
  'communications',
  // 'event_delay',
]);

export const SlateLanguage = {
  hasChangesMessage:
    'You have unsaved changes on this page, are you sure you want to leave?',
};

export const Resources = {
  graph: ['graph'],
  sequences: ['sequence'],
  tiles: ['tile'],
  reminders: ['reminder'],
};

export const NavKeys = {
  studies: 'studies',
  overview: 'overview',
  participants: 'participants',
  permissions: 'permissions',
  edit: 'edit',
  manual_approval: 'manual_approval',
  event_delays: 'event_delays',
  delete_user: 'delete_user',
  upload_review: 'upload_review',
  icf_review: 'icf_review',
};

// should mirror the actions in https://github.com/evidation-platform/triage/blob/main/app/models/role.rb
export const AuthActions = {
  roles: {
    index: 'roles/index',
    create: 'roles/create',
    update: 'roles/update',
  },
  enrollments: {
    update_data: 'enrollments/update_data',
    search: 'enrollments/search',
    compensate: 'enrollments/compensate',
    create_event: 'enrollments/create_event',
    fast_forward: 'enrollments/fast_forward',
    status_update: 'enrollments/status_update',
    bulk_update: 'enrollments/bulk_update',
    cra_search: 'enrollments/cra_search',
    process_contributions: 'enrollments/process_contributions',
  },
  enrollment_nodes: {
    status_update: 'enrollment_nodes/status_update',
  },
  studies: {
    update: 'studies/update',
    event_delays: 'studies/event_delays',
    info: 'studies/info',
    metrics: 'studies/metrics',
    icf_pdf: 'studies/icf_pdf',
    icf_pdfs: 'studies/icf_pdfs',
  },
  tiles: {
    show: 'tiles/show',
    create: 'tiles/create',
    update: 'tiles/update',
    destroy: 'tiles/destroy',
    preview: 'tiles/preview',
  },
  graphs: {
    show: 'graphs/show',
    index: 'graphs/index',
    update: 'graphs/update',
    publish: 'graphs/publish',
  },
  reminders: {
    show: 'reminders/show',
    create: 'reminders/create',
    update: 'reminders/update',
    destroy: 'reminders/destroy',
  },
  sequences: {
    show: 'sequences/show',
    create: 'sequences/create',
    update: 'sequences/update',
    destroy: 'sequences/destroy',
  },
  cohorts: {
    index: 'cohorts/index',
    create: 'cohorts/create',
    destroy: 'cohorts/destroy',
    event_identifiers: 'cohorts/event_identifiers',
    csv_download: 'cohorts/csv_download',
  },
  uploads: {
    new: 'uploads/new',
  },
  admin: {
    delete_user: 'enrollments/delete_user',
  },
  files: {
    index: 'file_contributions/index',
    download: 'file_contributions/download_url',
    review: 'file_contributions/reviewed_at_update',
  },
  informed_consents: {
    index: 'informed_consents/index',
    download: 'informed_consents/download_url',
    resend: 'informed_consents/resend_icf',
  },
  experian: {
    verify_otp: 'experian/verify_otp',
  },
  publishes: {
    index: 'publishes/index',
  },
};

export const roles = {
  hor: 'hor',
  owner: 'owner',
  publisher: 'publisher',
  clientSuccess: 'client_success',
  productionSupport: 'production_support',
  cra: 'cra',
  reviewer: 'reviewer',
};

// array of auth action strings
export const FlatAuthActions = _.reduce(
  AuthActions,
  (acc, val) => {
    return _.concat(acc, _.values(val));
  },
  [] as string[],
);

// these properties are used in application state for various visual
// effects. This list is used to filter out these params when transmitting
// data to our apis.
export const ResourceApplicationDisplayProperties = [
  'selected',
  'displayReminders',
];

export const default_experience_key = 'default';

export const editable_fields_in_preview = [
  'file',
  'email',
  'tel',
  'markdown',
  'scrollbox',
  'scrollboxsmall',
  'scrollboxlarge',
  'label',
  'heading',
  'paragraph',
  'radioinput',
  'radio',
  'checkbox',
  'checkboxinput',
  'checkboxgroup',
  'checkboxgroupwithnone',
  'checkboxwithnone',
  'text',
  'textinput',
  'autosize',
  'textareaautosize',
  'maskinput',
  'mask',
  'numberinput',
  'textareainput',
  'textarea',
  'state',
  'select',
  'selectinput',
  'selectadvanced',
  'heightinput',
  'signature',
  'signiturepad',
  'telinput',
  'emailinput',
  'passwordonly',
  'password',
  'date',
  'zip',
  'contentacceptance',
  'time',
  'typeahead',
  'todays_date',
];

export const single_experience_tile_types = [
  'api_request',
  'email_message',
  'delay',
  'disconnect_app',
  'terminal_state',
  'assign_external_id',
  'connect_ehr',
  'communications',
  'connect_camcog',
  'shipping',
  'experian_validator',
  'mtl_kit_order',
  'castor_edc',
  'castor_epro_survey',
  'redirect_user',
];

export const matrix_days_range_values = [
  { label: 'not at all', answer: 'not_at_all' },
  { label: 'several days', answer: 'several_days' },
  {
    label: 'more than half the days',
    answer: 'more_than_half_the_days',
  },
  {
    label: 'nearly every day',
    answer: 'nearly_every_day',
  },
];

export const matrix_days_range_values_na = [
  ...matrix_days_range_values,
  { label: 'N/A', value: 'n_a' },
];

export const matrix_range_0to10 = ascendingOptionListOfInt(10);
export const matrix_range_0to10_na = [
  ...ascendingOptionListOfInt(10),
  { label: 'N/A', value: 'n_a' },
];
export const matrix_range_0to5 = ascendingOptionListOfInt(5);
export const matrix_range_0to5_na = [
  ...ascendingOptionListOfInt(5),
  { label: 'N/A', value: 'n_a' },
];

export const SEQUENCE_PADDING = 10;
export const SEQUENCE_WIDTH = 340 - SEQUENCE_PADDING;
export const SEQUENCE_LEFT_MARGIN = 30 - SEQUENCE_PADDING;
export const GLOBAL_HEADER_HEIGHT = '62px';

export const event_statuses = [
  'assigned',
  'available',
  'cancelled',
  'changed',
  'closed',
  'completed',
  'created',
  'disqualified',
  'expired',
  'failed',
  'open',
  'requested',
  'sent',
  'signed',
  'started',
  'submitted',
  'validated',
  'visible',
];

export const node_statuses = [
  'visible',
  'available',
  'in_progress',
  'completed',
  'disqualified',
  'reconsent_required',
  'suspended',
];

export const enrollment_statuses = [
  'opted_out',
  'timed_out',
  'timing_out',
  'withdrawing',
  'disqualified',
  'disqualifying',
  'study_completed',
  'enrollment_completed',
  'invitation_clicked',
  'invited',
  'pending',
  'suspended',
];

export const queryStores = {
  studyVersions: 'versions',
  versionNotes: 'versionNotes',
  workerStatus: 'workerStatus',
  flowApplications: 'flowApplications',
  flowWearableProviders: 'flowWearableProviders',
  flowFields: 'flowFields',
  studyBrand: 'brand',
  tilesData: 'tilesData',
  tilesExperiences: 'tilesExperiences',
  tilePages: 'tilePages',
  tileComponents: 'tileComponents',
  componentsData: 'componentsData',
  nodeDependencies: 'nodeDependencies',
};

export const NON_EDITABLE_STATUSES = ['data_close', 'data_lock', 'completed'];

export const query_params = {
  pageId: 'page_id',
  pageTitle: 'page_title',
  formId: 'form_id',
  componentId: 'component_id',
  locale: 'locale',
};
