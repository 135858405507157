import React from 'react';
import CuriousPanda from '../../../../../../components/fields/CuriousPanda';
import useConnectionSchema from './useConnectionSchema';
import { noop } from '../../../../../../utils/noop';
import { ConnectionContainer, Panel, Content } from './components';

export default function CreateConnectionForm({
  initialValues,
  onChange,
  slug,
  connId,
  currentConnections,
}) {
  const { schema } = useConnectionSchema({
    slug,
    initialValues,
    currentConnections,
    connId,
  });
  return (
    <ConnectionContainer>
      <Panel>Connection Configuration</Panel>
      <Content>
        <CuriousPanda
          schema={schema}
          initialValues={initialValues}
          enableReinitialize
          keepDirtyOnReinitialize
          form={`connect_app_settings_form_${connId}`}
          onChange={onChange}
          onSubmit={noop}
        >
          {(p) => <div />}
        </CuriousPanda>
      </Content>
    </ConnectionContainer>
  );
}
