import React from 'react';
import CreateConnectionForm from './CreateConnectionForm';
import useConnectionForm from './useConnectionForm';
import { ConnectionsContainer } from './components';

export default function ConnectAppConnectionForm({
  initialValues,
  onChange,
  slug,
}) {
  const { connections, handleConnectionFormChange, handleRemoveConnection } =
    useConnectionForm({
      onChange,
      initialValues,
    });

  return (
    <ConnectionsContainer data-testid="connections_container">
      <CreateConnectionForm
        key={`create_connection_form_item_${0}`}
        initialValues={connections[0]}
        connId={0}
        currentConnections={connections?.map(
          (item) => item?.connection?.achievement_slug,
        )}
        onChange={(values, ...args) =>
          handleConnectionFormChange(0, values, ...args)
        }
        onRemove={handleRemoveConnection}
        slug={slug}
      />
    </ConnectionsContainer>
  );
}
