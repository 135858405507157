import { AuthActions, default_experience_key } from '../../../../constants';
import ContentForm from './Forms/Content';
import SettingsForm from './Forms/Settings';
import ConnectionForm from './Forms/Connection';
import { Button } from '@evidation/form-elements';
import { AuthWrap } from '../../../../components/auth';
import useConnectWearableForm from './useConnectWearableForm';
import ConnectAppCommonSettingsForm from './Forms/Connection/CommonSettings';

const requiredActions = [AuthActions.tiles.update, AuthActions.graphs.publish];

export default function ConnectWearableForm({
  initialValues,
  graph: {
    content: { slug, display_groups = [] },
    masonry_enabled = false,
  },
  onSubmit,
  experience_key = default_experience_key,
  curiousPandaChangeHandler,
  contentHasChanged,
}) {
  const {
    transformedInitialValues,
    handleFormChange,
    isDisabled,
    handleSubmit,
    connections,
  } = useConnectWearableForm({
    onSubmit,
    experience_key,
    initialValues,
    curiousPandaChangeHandler,
    contentHasChanged,
    display_groups,
  });

  return (
    <div>
      <ContentForm
        initialValues={transformedInitialValues}
        onChange={(values, ...args) =>
          handleFormChange('content', values, ...args)
        }
        masonryEnabled={masonry_enabled}
      />
      <ConnectionForm
        initialValues={transformedInitialValues}
        onChange={(values, ...args) =>
          handleFormChange('connection', values, ...args)
        }
        slug={slug}
      />
      {connections?.[0]?.achievement_slug && (
        <ConnectAppCommonSettingsForm
          initialValues={initialValues}
          onChange={(values, ...args) =>
            handleFormChange('settings', values, ...args)
          }
          slug={slug}
        />
      )}
      {!masonry_enabled && (
        <SettingsForm
          initialValues={transformedInitialValues}
          displayGroupNames={display_groups.map((group) => group.name)}
          onChange={(values, ...args) =>
            handleFormChange('settings', values, ...args)
          }
        />
      )}
      <AuthWrap requiredActions={requiredActions}>
        <Button
          type="button"
          buttonStyle="achievement"
          style={{ width: '100%' }}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </AuthWrap>
    </div>
  );
}
