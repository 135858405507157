import ConnectWearableForm from './ConnectWearableForm';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  form: {
    Settings: () => [],
  },
  Component: ConnectWearableForm,
  incoming,
  outgoing,
};
