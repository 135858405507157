import { triageV2, triageV3 } from './triage';

export const fetchAvailableServices = (slugId = '') =>
  triageV2({
    method: 'GET',
    url: `studies/${slugId}/flow_applications`,
  }).then((res) => res.data);

export const fetchWearableProviders = () =>
  triageV3({
    method: 'GET',
    url: `wearables/providers`,
  }).then((res) => res.data);

export const fetchFieldsByService = (slugId = '', serviceName) =>
  triageV3({
    method: 'GET',
    url: `workflows/${slugId}/${serviceName}`,
  }).then((res) => res.data);
